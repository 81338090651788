import { useParams } from "react-router-dom";
import PrivacyPolicy from "../components/policies/PrivacyPolicy";
import TermsOfService from "../components/policies/TermsOfService";

function getHeader(termsName: string | undefined): string {
  switch (termsName) {
    case "privacy":
      return "Privacy Policy";
    case "service":
      return "Terms of Service";
    case 'purchase':
      return "Terms of Purchase";
    default:
      return "Terms of Service";
  }
}

function validTermsName(termsName: string | undefined): boolean {
  if (!termsName) return false;
  return termsName === "privacy" || termsName === "service" || termsName === "purchase";
}

export default function TermsPage() {
  const { termsName } = useParams();
  if (!validTermsName(termsName)) return <h1>404: Not Found</h1>;

  if (termsName === 'privacy') return <PrivacyPolicy />

  if (termsName === 'service') return <TermsOfService />

  return (
    <div className="terms-page">
      <h1>{getHeader(termsName)}</h1>
    </div>
  );
}
