import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import TermsPage from './pages/TermsPage';

function HelloWorld() {
  return <h1>Hello World</h1>;
}

function App() {
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route path="/terms/:termsName" element={<TermsPage />} />
        </Routes>
      </BrowserRouter>
    </main>

  );
}

export default App;
